<template>
  <transition name="menu">
    <form-userpos :is-edit="true"/>
  </transition>
</template>

<script>
import formUserpos from './components/formUserpos'

export default {
  name: 'EditformUserpos',
  components: { formUserpos }
}
</script>

